import React from 'react';
import '../assets/css/CardAddedPopup.css';

const CardRemoved = ({onClose, card}) => {
    return (
        <div className="popup-overlay">
            <div className="popup-container">
                <button className="close-button" onClick={onClose}><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <path d="M15 2.5C8.1125 2.5 2.5 8.1125 2.5 15C2.5 21.8875 8.1125 27.5 15 27.5C21.8875 27.5 27.5 21.8875 27.5 15C27.5 8.1125 21.8875 2.5 15 2.5ZM19.2 17.875C19.5625 18.2375 19.5625 18.8375 19.2 19.2C19.0125 19.3875 18.775 19.475 18.5375 19.475C18.3 19.475 18.0625 19.3875 17.875 19.2L15 16.325L12.125 19.2C11.9375 19.3875 11.7 19.475 11.4625 19.475C11.225 19.475 10.9875 19.3875 10.8 19.2C10.4375 18.8375 10.4375 18.2375 10.8 17.875L13.675 15L10.8 12.125C10.4375 11.7625 10.4375 11.1625 10.8 10.8C11.1625 10.4375 11.7625 10.4375 12.125 10.8L15 13.675L17.875 10.8C18.2375 10.4375 18.8375 10.4375 19.2 10.8C19.5625 11.1625 19.5625 11.7625 19.2 12.125L16.325 15L19.2 17.875Z" fill="#A6A6A6" />
                </svg></button>
                <div className="icon-container">
                    <svg xmlns="http://www.w3.org/2000/svg" width="73" height="72" viewBox="0 0 73 72" fill="none">
                        <path d="M57.1992 45.09C50.5692 45.09 45.1992 50.46 45.1992 57.09C45.1992 63.72 50.5692 69.09 57.1992 69.09C63.8292 69.09 69.1992 63.72 69.1992 57.09C69.1992 50.46 63.8292 45.09 57.1992 45.09ZM61.9992 62.04C61.5492 62.49 60.9792 62.7 60.4092 62.7C59.8392 62.7 59.2692 62.49 58.8192 62.04L57.2292 60.45L55.5792 62.1C55.1292 62.55 54.5592 62.76 53.9892 62.76C53.4192 62.76 52.8492 62.55 52.3992 62.1C51.5292 61.23 51.5292 59.79 52.3992 58.92L54.0492 57.27L52.4592 55.68C51.5892 54.81 51.5892 53.37 52.4592 52.5C53.3292 51.63 54.7692 51.63 55.6392 52.5L57.2292 54.09L58.7292 52.59C59.5992 51.72 61.0392 51.72 61.9092 52.59C62.7792 53.46 62.7792 54.9 61.9092 55.77L60.4092 57.27L61.9992 58.86C62.8692 59.73 62.8692 61.17 61.9992 62.04Z" fill="#F65C5C" />
                        <path d="M66.5 22.65V24C66.5 25.65 65.15 27 63.5 27H9.5C7.85 27 6.5 25.65 6.5 24V22.62C6.5 15.75 12.05 10.2 18.92 10.2H54.05C60.92 10.2 66.5 15.78 66.5 22.65Z" fill="#F65C5C" />
                        <path d="M6.5 34.5V49.38C6.5 56.25 12.05 61.8 18.92 61.8H37.7C39.44 61.8 40.94 60.33 40.79 58.59C40.37 54 41.84 49.02 45.92 45.06C47.6 43.41 49.67 42.15 51.92 41.43C55.67 40.23 59.3 40.38 62.51 41.46C64.46 42.12 66.5 40.71 66.5 38.64V34.47C66.5 32.82 65.15 31.47 63.5 31.47H9.5C7.85 31.5 6.5 32.85 6.5 34.5ZM24.5 51.75H18.5C17.27 51.75 16.25 50.73 16.25 49.5C16.25 48.27 17.27 47.25 18.5 47.25H24.5C25.73 47.25 26.75 48.27 26.75 49.5C26.75 50.73 25.73 51.75 24.5 51.75Z" fill="#F65C5C" />
                    </svg>
                </div>
                <h2 className='card-add-popup-main'>Card Removed!</h2>
                <p className='card-add-popup-sub'>card **** **** **** {card.last4} has been removed successfully.</p>
                <button className=" remove-ok-btn" onClick={onClose}>OK</button>
            </div>
        </div>
    );
};

export default CardRemoved;
