import Cookies from 'universal-cookie';

const cookies = new Cookies(null, {path: '/'});

export const createUser = async (user) => {
    user.time = new Date();
    cookies.set('user', user);
};

export const updateUser = async (user) => {
    cookies.set('user', user);
};

export const getUser = async (redirect = true) => {
    let user = cookies.get('user'),
        currentDate = new Date();

    if ((!user || !(new Date(user.time) > currentDate.setHours(currentDate.getHours() - 3))) && redirect) {
        await deleteUser();
        window.location.href = '/login';
    }
    return cookies.get('user');
};

export const deleteUser = async () => {
    cookies.remove('user');
};

export const createEmail = async (email) => {
    cookies.set('email', {email: email, time: new Date()});
};

export const getEmail = async (redirect = true) => {
    let email = cookies.get('email'),
        currentDate = new Date();

    if ((!email && redirect) || !(new Date(email.time) > currentDate.setMinutes(currentDate.getMinutes() - 5))) {
        await deleteEmail();
        window.location.href = '/email-verification';
    }

    return cookies.get('email');
};

export const deleteEmail = async () => {
    cookies.remove('email');
};

export const createSelectedPricingPackage = async (pricingPackage) => {
    cookies.set('selectedPricingPackage', pricingPackage);
};

export const getSelectedPricingPackage = async (redirect = true) => {
    let selectedPricingPackage = cookies.get('selectedPricingPackage');

    if (!selectedPricingPackage && redirect) {
        window.location.href = '/price';
    }

    return cookies.get('selectedPricingPackage');
};

export const deleteSelectedPricingPackage = async () => {
    cookies.remove('selectedPricingPackage');
};

export const createNewUser = async (user) => {
    cookies.set('newUser', user);
};

export const getNewUser = async () => {
    return cookies.get('newUser');
};

export const deleteNewUser = async () => {
    cookies.remove('newUser');
};
