import React from 'react';
import {Link} from "react-router-dom";

function CTABanner({ctaBanner, showGreenContainer = false}) {
    return (
        <div className={'section-bottom-home-content' + (showGreenContainer ? "" : " adj20")}>
            <div className='section-bottom-home-image'>
                <picture>
                    <source media="(max-width: 767px)" srcSet={ctaBanner.image} alt="/"/>
                    <source media="(max-width: 1023px)" srcSet={ctaBanner.image} alt=""/>
                    <img src={ctaBanner.image} alt="background"/>
                </picture>
            </div>
            <div className='text'>
                <h2 className="textarea-description">{ctaBanner.description}</h2>
                {ctaBanner.link.includes(window.location.hostname) ?
                    <Link className="main-banner-slider-btn"
                          to={ctaBanner.link}
                          style={{
                              backgroundColor: ctaBanner.button_colour,
                              color: ctaBanner.text_colour
                          }}>
                        {ctaBanner.button}
                    </Link>
                    : <a href={ctaBanner.link} className='main-banner-slider-btn' target="_blank"
                         style={{
                             backgroundColor: ctaBanner.button_colour,
                             color: ctaBanner.text_colour
                         }}>
                        {ctaBanner.button}
                    </a>
                }
            </div>
            {showGreenContainer &&
            <div className='green-bottom'>
                <span>“It’s the mind itself which shapes the body”</span>
            </div>
            }
        </div>
    );
}

export default CTABanner;
