import React, {useEffect, useState} from 'react';
import '../assets/css/PricesPage.css';
import pricebanner from "../assets/images/pricebanner.jpeg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {loadCTABanner} from "../common/Common";
import PriceCards from '../components/PriceCards';
import CTABanner from "../components/CTABanner";
import Loading from "../components/Loading";

export default function Prices() {
    const [ctaBanner, setCtaBanner] = useState(null),
        [loading, setLoading] = useState(true);

    useEffect(() => {
        loadCTABanner("pricing").then((data) => {
            setCtaBanner(data);
            setLoading(false);
        }).catch(() => null);
    }, []);

    if (!loading) {
        return (
            <div className='contact-main body-main'>
                <div className='contact-container'>
                    <div className='top-banner-default'>
                        <div className='text-section series-container'>
                            <span className='bdc-txt '>
                              Home / Subscriptions
                            </span>
                            <span href="" className='mani-pg-name'>Subscriptions</span>
                        </div>
                        <div className='abs-slider-images'>
                            <picture>
                                <source media="(max-width: 767px)" srcset={pricebanner} alt="/"/>
                                <source media="(max-width: 1023px)" srcset={pricebanner} alt=""/>
                                <img src={pricebanner} alt="background"/>
                            </picture>
                        </div>
                    </div>
                    <div className='special-wrapper-howto'>
                        <div className='price-howto series-container'>
                            <PriceCards/>
                        </div>
                    </div>
                    {ctaBanner &&
                    <CTABanner ctaBanner={ctaBanner}/>
                    }
                </div>
            </div>
        )
    } else {
        return (
            <Loading/>
        );
    }
}
