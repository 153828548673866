import React, {useEffect, useState} from 'react';
import NewAccountBanner from "../assets/images/newaccountBanner.jpeg";
import '../assets/css/NewAccount.css';
import {Link} from "react-router-dom";
import {createNewUser, getEmail} from "../auth/Auth";
import Loading from "../components/Loading";
import logo from "../assets/images/logo-dark.png";

function NewAccount() {
    const [email, setEmail] = useState(null),
        [password, setPassword] = useState(''),
        [confirmPassword, setConfirmPassword] = useState(''),
        [passwordError, setPasswordError] = useState(''),
        [confirmPasswordError, setConfirmPasswordError] = useState(''),
        [isChecked, setIsChecked] = useState(false),
        [showPassword, setShowPassword] = useState(false),
        [loading, setLoading] = useState(false);

    useEffect(() => {
        getEmail().then((data) => {
            setEmail(data);
        }).catch(() => {
            setEmail(null);
        });
    }, []);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleLogin = (event) => {
        event.preventDefault();
        let valid = true;

        if (password.length < 6) {
            setPasswordError('Password must contain at least 6 characters.');
            valid = false;
        } else {
            setPasswordError('');
        }

        if (confirmPassword.length < 6) {
            setConfirmPasswordError('Confirm password must contain at least 6 characters.');
            valid = false;
        } else {
            setConfirmPasswordError('');
        }

        if (password !== confirmPassword) {
            setConfirmPasswordError('Passwords do not match.');
            valid = false;
        } else {
            setConfirmPasswordError('');
        }

        if (valid) {
            setLoading(true);
            let user = {
                first_name: event.target.first_name.value,
                last_name: event.target.last_name.value,
                email: email.email,
                password: password,
            };
            createNewUser(user).then(() => {
                setLoading(false);
                window.location.href = '/select-package';
            });
        }
    };

    if (email) {
        return (
            <>
                <div className=' body-main new-acct-flex new-acct-container'>
                    <div className="new-acct-container left-new-acct-div">
                        <img src={logo} alt='Logo'/>
                        <h2 className='welcome-back-tag'>Create an account</h2>
                        <span className='welcome-sub-tag'>Please enter your personal details </span>
                        <form onSubmit={handleLogin}>
                            <div className='first-last-name-main'>
                                <div className="form-group spc-f-g1">
                                    <label htmlFor="first_name">First Name</label>
                                    <div className='input-first-last-name'>
                                        <input
                                            type="text"
                                            id="first_name"
                                            name="first_name"
                                            placeholder="Janet"
                                        />
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             viewBox="0 0 16 16"
                                             fill="none">
                                            <g id="vuesax/bold/frame">
                                                <g id="frame">
                                                    <path id="Vector"
                                                          d="M7.99967 1.3335C6.25301 1.3335 4.83301 2.7535 4.83301 4.50016C4.83301 6.2135 6.17301 7.60016 7.91967 7.66016C7.97301 7.6535 8.02634 7.6535 8.06634 7.66016C8.07967 7.66016 8.08634 7.66016 8.09967 7.66016C8.10634 7.66016 8.10634 7.66016 8.11301 7.66016C9.81967 7.60016 11.1597 6.2135 11.1663 4.50016C11.1663 2.7535 9.74634 1.3335 7.99967 1.3335Z"
                                                          fill="#CCCCCC"/>
                                                    <path id="Vector_2"
                                                          d="M11.3866 9.43342C9.52664 8.19342 6.49331 8.19342 4.61997 9.43342C3.77331 10.0001 3.30664 10.7668 3.30664 11.5868C3.30664 12.4068 3.77331 13.1668 4.61331 13.7268C5.54664 14.3534 6.77331 14.6668 7.99997 14.6668C9.22664 14.6668 10.4533 14.3534 11.3866 13.7268C12.2266 13.1601 12.6933 12.4001 12.6933 11.5734C12.6866 10.7534 12.2266 9.99342 11.3866 9.43342Z"
                                                          fill="#CCCCCC"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                                <div className="form-group spc-f-g1">
                                    <label htmlFor="last_name">Last Name</label>
                                    <div className='input-first-last-name'>
                                        <input
                                            type="text"
                                            id="last_name"
                                            name="last_name"
                                            placeholder="Lawrence"
                                        />
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             viewBox="0 0 16 16"
                                             fill="none">
                                            <g id="vuesax/bold/frame">
                                                <g id="frame">
                                                    <path id="Vector"
                                                          d="M7.99967 1.3335C6.25301 1.3335 4.83301 2.7535 4.83301 4.50016C4.83301 6.2135 6.17301 7.60016 7.91967 7.66016C7.97301 7.6535 8.02634 7.6535 8.06634 7.66016C8.07967 7.66016 8.08634 7.66016 8.09967 7.66016C8.10634 7.66016 8.10634 7.66016 8.11301 7.66016C9.81967 7.60016 11.1597 6.2135 11.1663 4.50016C11.1663 2.7535 9.74634 1.3335 7.99967 1.3335Z"
                                                          fill="#CCCCCC"/>
                                                    <path id="Vector_2"
                                                          d="M11.3866 9.43342C9.52664 8.19342 6.49331 8.19342 4.61997 9.43342C3.77331 10.0001 3.30664 10.7668 3.30664 11.5868C3.30664 12.4068 3.77331 13.1668 4.61331 13.7268C5.54664 14.3534 6.77331 14.6668 7.99997 14.6668C9.22664 14.6668 10.4533 14.3534 11.3866 13.7268C12.2266 13.1601 12.6933 12.4001 12.6933 11.5734C12.6866 10.7534 12.2266 9.99342 11.3866 9.43342Z"
                                                          fill="#CCCCCC"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">New Password</label>
                                <div className='input-pw-logo password-svg'>
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        id="password"
                                        name="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder="......."
                                        className={passwordError ? 'input-error' : ''}
                                    />
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                         fill="none" onClick={togglePasswordVisibility}>
                                        <g id="vuesax/bold/eye-slash">
                                            <path id="Vector"
                                                  d="M14.1796 6.12003C13.9863 5.81337 13.7796 5.5267 13.5663 5.26003C13.3196 4.9467 12.853 4.92003 12.573 5.20003L10.573 7.20003C10.7196 7.64003 10.7463 8.1467 10.613 8.67337C10.3796 9.61337 9.61964 10.3734 8.67964 10.6067C8.15297 10.74 7.64631 10.7134 7.20631 10.5667C7.20631 10.5667 6.25297 11.52 5.56631 12.2067C5.23297 12.54 5.33964 13.1267 5.78631 13.3C6.49964 13.5734 7.23964 13.7134 7.99964 13.7134C9.18631 13.7134 10.3396 13.3667 11.393 12.72C12.4663 12.0534 13.433 11.0734 14.213 9.8267C14.8463 8.82003 14.813 7.1267 14.1796 6.12003Z"
                                                  fill="#CCCCCC"/>
                                            <path id="Vector_2"
                                                  d="M9.34708 6.65326L6.65375 9.3466C6.31375 8.99993 6.09375 8.51993 6.09375 7.99993C6.09375 6.95326 6.94708 6.09326 8.00042 6.09326C8.52042 6.09326 9.00042 6.31326 9.34708 6.65326Z"
                                                  fill="#CCCCCC"/>
                                            <path id="Vector_3"
                                                  d="M12.1663 3.83329L9.90634 6.09329C9.41967 5.59995 8.74634 5.30662 7.99967 5.30662C6.50634 5.30662 5.30634 6.51329 5.30634 7.99995C5.30634 8.74662 5.60634 9.41995 6.09301 9.90662L3.83967 12.1666H3.83301C3.09301 11.5666 2.41301 10.8 1.83301 9.89329C1.16634 8.84662 1.16634 7.14662 1.83301 6.09995C2.60634 4.88662 3.55301 3.93329 4.60634 3.27995C5.65967 2.63995 6.81301 2.28662 7.99967 2.28662C9.48634 2.28662 10.9263 2.83329 12.1663 3.83329Z"
                                                  fill="#CCCCCC"/>
                                            <path id="Vector_4"
                                                  d="M9.90672 8.00005C9.90672 9.04672 9.05339 9.90672 8.00005 9.90672C7.96005 9.90672 7.92672 9.90672 7.88672 9.89339L9.89339 7.88672C9.90672 7.92672 9.90672 7.96005 9.90672 8.00005Z"
                                                  fill="#CCCCCC"/>
                                            <path id="Vector_5"
                                                  d="M14.5136 1.48691C14.3136 1.28691 13.9869 1.28691 13.7869 1.48691L1.48691 13.7936C1.28691 13.9936 1.28691 14.3202 1.48691 14.5202C1.58691 14.6136 1.71358 14.6669 1.84691 14.6669C1.98025 14.6669 2.10691 14.6136 2.20691 14.5136L14.5136 2.20691C14.7202 2.00691 14.7202 1.68691 14.5136 1.48691Z"
                                                  fill="#CCCCCC"/>
                                        </g>
                                    </svg>
                                </div>
                                {passwordError && <p className="error-text">{passwordError}</p>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="confirm_password">Confirm New Password</label>
                                <div className='input-pw-logo password-svg'>
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        id="confirm_password"
                                        name="confirm_password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        placeholder="......."
                                        className={confirmPasswordError ? 'input-error' : ''}
                                    />
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                         fill="none" onClick={togglePasswordVisibility}>
                                        <g id="vuesax/bold/eye-slash">
                                            <path id="Vector"
                                                  d="M14.1796 6.12003C13.9863 5.81337 13.7796 5.5267 13.5663 5.26003C13.3196 4.9467 12.853 4.92003 12.573 5.20003L10.573 7.20003C10.7196 7.64003 10.7463 8.1467 10.613 8.67337C10.3796 9.61337 9.61964 10.3734 8.67964 10.6067C8.15297 10.74 7.64631 10.7134 7.20631 10.5667C7.20631 10.5667 6.25297 11.52 5.56631 12.2067C5.23297 12.54 5.33964 13.1267 5.78631 13.3C6.49964 13.5734 7.23964 13.7134 7.99964 13.7134C9.18631 13.7134 10.3396 13.3667 11.393 12.72C12.4663 12.0534 13.433 11.0734 14.213 9.8267C14.8463 8.82003 14.813 7.1267 14.1796 6.12003Z"
                                                  fill="#CCCCCC"/>
                                            <path id="Vector_2"
                                                  d="M9.34708 6.65326L6.65375 9.3466C6.31375 8.99993 6.09375 8.51993 6.09375 7.99993C6.09375 6.95326 6.94708 6.09326 8.00042 6.09326C8.52042 6.09326 9.00042 6.31326 9.34708 6.65326Z"
                                                  fill="#CCCCCC"/>
                                            <path id="Vector_3"
                                                  d="M12.1663 3.83329L9.90634 6.09329C9.41967 5.59995 8.74634 5.30662 7.99967 5.30662C6.50634 5.30662 5.30634 6.51329 5.30634 7.99995C5.30634 8.74662 5.60634 9.41995 6.09301 9.90662L3.83967 12.1666H3.83301C3.09301 11.5666 2.41301 10.8 1.83301 9.89329C1.16634 8.84662 1.16634 7.14662 1.83301 6.09995C2.60634 4.88662 3.55301 3.93329 4.60634 3.27995C5.65967 2.63995 6.81301 2.28662 7.99967 2.28662C9.48634 2.28662 10.9263 2.83329 12.1663 3.83329Z"
                                                  fill="#CCCCCC"/>
                                            <path id="Vector_4"
                                                  d="M9.90672 8.00005C9.90672 9.04672 9.05339 9.90672 8.00005 9.90672C7.96005 9.90672 7.92672 9.90672 7.88672 9.89339L9.89339 7.88672C9.90672 7.92672 9.90672 7.96005 9.90672 8.00005Z"
                                                  fill="#CCCCCC"/>
                                            <path id="Vector_5"
                                                  d="M14.5136 1.48691C14.3136 1.28691 13.9869 1.28691 13.7869 1.48691L1.48691 13.7936C1.28691 13.9936 1.28691 14.3202 1.48691 14.5202C1.58691 14.6136 1.71358 14.6669 1.84691 14.6669C1.98025 14.6669 2.10691 14.6136 2.20691 14.5136L14.5136 2.20691C14.7202 2.00691 14.7202 1.68691 14.5136 1.48691Z"
                                                  fill="#CCCCCC"/>
                                        </g>
                                    </svg>
                                </div>
                                {confirmPasswordError && <p className="error-text">{confirmPasswordError}</p>}
                            </div>
                            <div className="form-group-one">
                                <input
                                    type="checkbox"
                                    className='checkbox'
                                    id="terms"
                                    checked={isChecked}
                                    onChange={(e) => setIsChecked(e.target.checked)}
                                    required
                                />
                                <label htmlFor="terms">
                                    I agree to the <Link to="/legal">Terms & Conditions</Link> and <Link to="/legal">Privacy
                                    Policy</Link>
                                </label>
                            </div>
                            <button type="submit" className="new-acct-button">
                                {loading ?
                                    <span className="spinner-grow spinner-grow-sm" role="status"
                                          aria-hidden="true"/>
                                    : null
                                }
                                Register
                            </button>
                        </form>

                        <div className="new-acct-footer">
                            <p>Already A member? <Link to="/login">Login here</Link></p>
                        </div>
                    </div>
                    <div className="new-acct-container right-new-acct-div">
                        <img src={NewAccountBanner} alt='NewAccountBanner'/>
                        <div className='new-acct-banner-abs-text'>
                            Explore 60+ Exciting Classes! Discover 2 New Ones Every Week.
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <Loading/>
        );
    }
}

export default NewAccount;
