import {toast} from "react-toastify";
import {getRequest} from "../routes/Routes";

export const loadTestimonials = async () => {
    let testimonials = [];
    await getRequest('/api/testimonial').then(response => {
        if (response.status === 200) {
            testimonials = response.data.testimonials;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return testimonials;
};

export const loadContactDetails = async () => {
    let contactDetails = [];
    await getRequest('/api/contact-detail').then(response => {
        if (response.status === 200) {
            contactDetails = response.data.contactDetails;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return contactDetails;
};

export const loadModules = async () => {
    let modules = [];
    await getRequest('/api/module').then(response => {
        if (response.status === 200) {
            modules = response.data.modules;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return modules;
};

export const loadLimitedAnnouncements = async (limit) => {
    let announcements = [];
    await getRequest('/api/announcement/limit/' + limit).then(response => {
        if (response.status === 200) {
            announcements = response.data.announcements;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return announcements;
};

export const loadHeroBanners = async () => {
    let heroBanners = [];
    await getRequest('/api/hero-banner').then(response => {
        if (response.status === 200) {
            heroBanners = response.data.heroBanners;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return heroBanners;
};

export const loadCTABanner = async (page) => {
    let ctaBanner = [];
    await getRequest('/api/cta-banner/page/' + page).then(response => {
        if (response.status === 200) {
            ctaBanner = response.data.ctaBanner;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return ctaBanner;
};

export const loadLimitedVideos = async (limit) => {
    let videos = [];
    await getRequest('/api/video/limit/' + limit).then(response => {
        if (response.status === 200) {
            videos = response.data.videos;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return videos;
};

export const loadCourses = async () => {
    let courses = [];
    await getRequest('/api/course').then(response => {
        if (response.status === 200) {
            courses = response.data.courses;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return courses;
};

export const loadLimitedCourses = async (limit) => {
    let courses = [];
    await getRequest('/api/course/limit/' + limit).then(response => {
        if (response.status === 200) {
            courses = response.data.courses;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return courses;
};

export const loadCoursesOnCategories = async () => {
    let courses = [];
    await getRequest('/api/course/group/categories').then(response => {
        if (response.status === 200) {
            courses = response.data.courses;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return courses;
};

export const loadClasses = async () => {
    let classes = [];
    await getRequest('/api/class').then(response => {
        if (response.status === 200) {
            classes = response.data.classes;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return classes;
};

export const loadClassesOnCategories = async () => {
    let classes = [];
    await getRequest('/api/class/group/categories').then(response => {
        if (response.status === 200) {
            classes = response.data.classes;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return classes;
};

export const loadPricingPackages = async () => {
    let pricingPackages = [];
    await getRequest('/api/pricing-package').then(response => {
        if (response.status === 200) {
            pricingPackages = response.data.pricingPackages;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return pricingPackages;
};

export const loadLegals = async () => {
    let legals = [];
    await getRequest('/api/legal').then(response => {
        if (response.status === 200) {
            legals = response.data.legals;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return legals;
};

export const loadUserPricingPackage = async (token) => {
    let pricingPackage = null;
    await getRequest('/api/user-pricing-package', token).then(response => {
        if (response.status === 200) {
            pricingPackage = response.data.pricingPackage;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return pricingPackage;
};

export const loadStripeCustomerSession = async (token) => {
    let customerSessionClientSecret = null;
    await getRequest('/api/user/stripe-customer-session', token).then(response => {
        if (response.status === 200) {
            customerSessionClientSecret = response.data.customerSessionClientSecret;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return customerSessionClientSecret;
};

export const loadStripeCustomerPaymentMethods = async (token) => {
    let paymentMethods = null;
    await getRequest('/api/user/stripe-customer-payment-methods', token).then(response => {
        if (response.status === 200) {
            paymentMethods = response.data;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return paymentMethods;
};

export const loadUserTransactions = async (token) => {
    let transactions = [];
    await getRequest('/api/transaction/user', token).then(response => {
        if (response.status === 200) {
            transactions = response.data.transactions;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return transactions;
};
