import React, {useState} from 'react';
import EmailBanner from "../assets/images/emailBanner.jpeg";
import '../assets/css/EmailVari.css';
import OtpPopup from '../components/OtpPopUp';
import {Link} from "react-router-dom";
import {postRequest} from "../routes/Routes";
import logo from "../assets/images/logo-dark.png";

function EmailVari() {
    const [email, setEmail] = useState(''),
        [emailError, setEmailError] = useState(''),
        [isChecked, setIsChecked] = useState(false),
        [showPopup, setShowPopup] = useState(false),
        [loading, setLoading] = useState(false),
        [error, setError] = useState({});

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const handleEmailVerification = (event) => {
        event.preventDefault();
        let valid = true;

        if (!validateEmail(email)) {
            setEmailError('Invalid email address. Please try again.');
            valid = false;
        } else {
            setEmailError('');
        }

        if (valid) {
            setLoading(true);
            let values = {
                email: email,
                endpoint: '/api/otp'
            };
            postRequest(values).then(response => {
                if (response.status === 200) {
                    if (response.data.error) {
                        setError(values => ({...values, error: true, errorMessage: response.data.message}));
                    } else {
                        setError(values => ({...values, error: false, errorMessage: null}));
                        handleShowPopup();
                    }
                } else {
                    setError(values => ({...values, error: true, errorMessage: "An error occurred!"}));
                }
                setLoading(false);
            });
        }
    };

    const handleShowPopup = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    return (
        <>
            <div className=' body-main email-vari-flex email-vari-container'>
                <div className="email-vari-container left-email-vari-div">
                    <a href="/">
                        <img src={logo} alt='Logo'/>
                    </a>
                    <h2 className='welcome-back-tag'>First, Enter your Email</h2>
                    <span className='welcome-sub-tag'>We suggest using your personal email. </span>
                    <form onSubmit={handleEmailVerification}>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <div className='input-mail-logo'>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Your Email Address"
                                    className={emailError ? 'input-error' : ''}
                                />
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                     fill="none">
                                    <path xmlns="http://www.w3.org/2000/svg"
                                          d="M11.334 2.3335H4.66732C2.66732 2.3335 1.33398 3.3335 1.33398 5.66683V10.3335C1.33398 12.6668 2.66732 13.6668 4.66732 13.6668H11.334C13.334 13.6668 14.6673 12.6668 14.6673 10.3335V5.66683C14.6673 3.3335 13.334 2.3335 11.334 2.3335ZM11.6473 6.3935L9.56065 8.06016C9.12065 8.4135 8.56065 8.58683 8.00065 8.58683C7.44065 8.58683 6.87398 8.4135 6.44065 8.06016L4.35398 6.3935C4.14065 6.22016 4.10732 5.90016 4.27398 5.68683C4.44732 5.4735 4.76065 5.4335 4.97398 5.60683L7.06065 7.2735C7.56732 7.68016 8.42732 7.68016 8.93398 7.2735L11.0207 5.60683C11.234 5.4335 11.554 5.46683 11.7207 5.68683C11.894 5.90016 11.8607 6.22016 11.6473 6.3935Z"
                                          fill="#CCCCCC"/>
                                </svg>
                            </div>
                            {emailError && <p className="error-text">{emailError}</p>}
                        </div>

                        <div className="form-group-one">
                            <input
                                type="checkbox"
                                className='checkbox'
                                id="terms"
                                checked={isChecked}
                                onChange={(e) => setIsChecked(e.target.checked)}
                                required
                            />
                            <label htmlFor="terms">
                                I agree to the <Link to="/legal">Terms & Conditions</Link> and <Link to="/legal">Privacy
                                Policy</Link>
                            </label>
                        </div>
                        {error.error &&
                        <div className="alert alert-danger" role="alert">
                            {error.errorMessage}
                        </div>}
                        <button type="submit" className="email-vari-button">
                            {loading ?
                                <span className="spinner-grow spinner-grow-sm" role="status"
                                      aria-hidden="true"/>
                                : null
                            }
                            Next
                        </button>
                        {showPopup && (
                            <OtpPopup email={email} onClose={handleClosePopup} resendOtp={handleEmailVerification}/>
                        )}
                    </form>

                    <div className="email-vari-footer">
                        <p>Already A member? <Link to="/login">Login here</Link></p>
                    </div>
                </div>
                <div className="email-vari-container right-email-vari-div">
                    <img src={EmailBanner} alt='emailBanner'/>
                    <div className='email-vari-banner-abs-text'>
                    Physiotherapist led Pilates at any time, anywhere!
                    </div>
                </div>
            </div>
        </>
    );
}

export default EmailVari;
