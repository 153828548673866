import React, {useEffect, useState} from 'react';
import '../assets/css/UserProfileSetting.css';
import {loadLegals} from "../common/Common";
import Loading from "./Loading";
import {postRequest, uploadFile} from "../routes/Routes";
import {toast} from "react-toastify";
import {deleteUser, updateUser} from "../auth/Auth";
import EditorJS from '@editorjs/editorjs';
import {getEditorJSTools} from "./editor-constants";
import {useDropzone} from 'react-dropzone';

let editor = undefined;

export default function ProfileUserSettings({user}) {
    const [activeIndex, setActiveIndex] = useState(null),
        [legals, setLegals] = useState([]),
        [loading, setLoading] = useState(false),
        [error, setError] = useState({}),
        [success, setSuccess] = useState({}),
        [userLoading, setUserLoading] = useState(false),
        [userError, setUserError] = useState({}),
        [userSuccess, setUserSuccess] = useState({}),
        [loadingContent, setLoadingContent] = useState(true),
        [password, setPassword] = useState(''),
        [newPassword, setNewPassword] = useState(''),
        [confirmPassword, setConfirmPassword] = useState(''),
        [passwordError, setPasswordError] = useState(''),
        [newPasswordError, setNewPasswordError] = useState(''),
        [confirmPasswordError, setConfirmPasswordError] = useState(''),
        [showPassword, setShowPassword] = useState(false),
        [image, setImage] = useState(null),
        [imageFile, setImageFile] = useState(null),
        [noImage, setNoImage] = useState(true);

    useEffect(() => {
        if (user.photo) {
            setNoImage(false);
        }
        loadLegals().then((data) => {
            setLegals(data);
            setLoadingContent(false);
        }).catch(() => null);
    }, []);

    const toggleFAQ = (index) => {
        editor = undefined;
        setActiveIndex(activeIndex === index ? null : index);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleUpdatePassword = (event) => {
        event.preventDefault();
        let valid = true;

        if (password.length < 6) {
            setPasswordError('Password must contain at least 6 characters.');
            valid = false;
        } else {
            setPasswordError('');
        }

        if (newPassword.length < 6) {
            setNewPasswordError('Password must contain at least 6 characters.');
            valid = false;
        } else {
            setNewPasswordError('');
        }

        if (confirmPassword.length < 6) {
            setConfirmPasswordError('Confirm password must contain at least 6 characters.');
            valid = false;
        } else {
            setConfirmPasswordError('');
        }

        if (newPassword !== confirmPassword) {
            setConfirmPasswordError('Passwords do not match.');
            valid = false;
        } else {
            setConfirmPasswordError('');
        }

        if (valid) {
            setLoading(true);
            let values = {
                token: user.token,
                password: event.target.password.value,
                new_password: event.target.new_password.value,
                endpoint: '/api/user/update-password'
            };
            postRequest(values).then(response => {
                if (response.status === 200) {
                    if (response.data.error) {
                        setError(values => ({...values, error: true, errorMessage: response.data.message}));
                    } else {
                        setError({});
                        setSuccess(values => ({...values, success: true, successMessage: response.data.message}));
                        toast.success(response.data.message, {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        deleteUser().then(() => {
                            window.location.href = '/login';
                        });
                    }
                } else {
                    setError(values => ({...values, error: true, errorMessage: "An error occurred!"}));
                }
                setLoading(false);
            });
        }
    };

    const handleUpdateUser = (event) => {
        event.preventDefault();
        let valid = true;

        if (valid) {
            setUserLoading(true);
            const data = new FormData();
            data.append('email', event.target.email.value);
            data.append('first_name', event.target.first_name.value);
            data.append('last_name', event.target.last_name.value);
            if (imageFile) {
                data.append('photo', imageFile);
            }
            uploadFile(data, '/api/user/update-user', user.token).then(response => {
                if (response.status === 200) {
                    if (response.data.error) {
                        setUserError(values => ({...values, error: true, errorMessage: response.data.message}));
                    } else {
                        setUserError({});
                        setUserSuccess(values => ({...values, success: true, successMessage: response.data.message}));
                        toast.success(response.data.message, {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        let tempUser = response.data.user;
                        tempUser.time = user.time;
                        tempUser.token = user.token;
                        updateUser(tempUser).then(() => {
                            window.location.href = '/profile';
                        });
                    }
                } else {
                    setUserError(values => ({...values, error: true, errorMessage: "An error occurred!"}));
                }
                setUserLoading(false);
            });
        }
    };

    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        setImage(Object.assign(file, {
            preview: URL.createObjectURL(file),
        }));
        setNoImage(false);
        setImageFile(file);
    };

    const {getRootProps, getInputProps} = useDropzone({
        onDrop,
        multiple: false,
    });

    const removeImage = () => {
        if (image) {
            URL.revokeObjectURL(image.preview);
        }
        setImage(null);
        setNoImage(true);
        setImageFile(null);
    };

    // if (!editor && activeIndex !== null && !loadingContent) {
    //     editor = new EditorJS({
    //         holder: 'editor' + activeIndex,
    //         tools: getEditorJSTools(null),
    //         readOnly: true,
    //         data: JSON.parse(legals[activeIndex].description),
    //     });
    // }

    if (!editor && activeIndex !== null) {
        try {
            const data = legals && legals[activeIndex]?.description
                ? isValidJSON(legals[activeIndex].description)
                    ? JSON.parse(legals[activeIndex].description)
                    : { blocks: [{ type: 'paragraph', data: { text: legals[activeIndex].description } }] } // Fallback for plain text
                : { blocks: [] };
            editor = new EditorJS({
                holder: 'editor' + activeIndex,
                tools: getEditorJSTools(null),
                placeholder: 'Legal Description',
                readOnly: true,
                data: data,
            });
        } catch (error) {
            console.error("Error initializing the editor:", error);
        }
    }
    function isValidJSON(str) {
        try {
            JSON.parse(str);
            return true;
        } catch {
            return false;
        }
    }
    
    return (
        <div className='body-main'>
            <div className='profile-user-setting-main-wrapper series-container'>
                <span className='main-name'>User Settings</span>
                <span className='sub-name'>Update personal information or reset your password</span>
            </div>

            <div className='uerSetting-personal-section series-container'>
                <div className='left-side-us'>
                    <span className='main-personal-ttl'>Personal Information</span>
                    <span className='sub-personal-ttl'>Update your photo and personal details</span>
                </div>
                <div className='right-side-us '>
                    <form className='user-data-page-form' onSubmit={handleUpdateUser}>
                        <div className='double-div-class'>
                            <div>
                                <label className='us-form-label'>First Name</label>
                                <input
                                    type="text"
                                    id="first_name"
                                    name="first_name"
                                    placeholder="Janet"
                                    defaultValue={user.first_name}
                                    required
                                />
                            </div>
                            <div>
                                <label className='us-form-label'>Last Name</label>
                                <input
                                    type="text"
                                    id="last_name"
                                    name="last_name"
                                    placeholder="Lawrence"
                                    defaultValue={user.last_name}
                                    required
                                />
                            </div>
                        </div>
                        <div className='single-div-class'>
                            <label className='us-form-label'>Email Address</label>
                            <div className='wrapper-icon-div-input'>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="jannet@gmail.com"
                                    value={user.email}
                                    required
                                    disabled
                                />
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                     fill="none">
                                    <path
                                        d="M11.332 2.33337H4.66536C2.66536 2.33337 1.33203 3.33337 1.33203 5.66671V10.3334C1.33203 12.6667 2.66536 13.6667 4.66536 13.6667H11.332C13.332 13.6667 14.6654 12.6667 14.6654 10.3334V5.66671C14.6654 3.33337 13.332 2.33337 11.332 2.33337ZM11.6454 6.39337L9.5587 8.06004C9.1187 8.41337 8.5587 8.58671 7.9987 8.58671C7.4387 8.58671 6.87203 8.41337 6.4387 8.06004L4.35203 6.39337C4.1387 6.22004 4.10536 5.90004 4.27203 5.68671C4.44536 5.47337 4.7587 5.43337 4.97203 5.60671L7.0587 7.27337C7.56536 7.68004 8.42536 7.68004 8.93203 7.27337L11.0187 5.60671C11.232 5.43337 11.552 5.46671 11.7187 5.68671C11.892 5.90004 11.8587 6.22004 11.6454 6.39337Z"
                                        fill="#CCCCCC"/>
                                </svg>
                            </div>
                        </div>
                        <div className='flex-user-photo'>
                            <div>
                                <div {...getRootProps()} className="image-uploader">
                                    <div className="image-preview-main">
                                        {noImage ?
                                            <p className="image-upload-box">
                                                Select image
                                            </p>
                                            :
                                            <div style={{position: 'relative', display: 'inline-block'}}>
                                                {user.photo && !image ?
                                                    <img
                                                        src={user.photo}
                                                        alt={user.first_name}
                                                        className="upload-image-preview"/>
                                                    : <img
                                                        src={image.preview}
                                                        alt={image.name}
                                                        className="upload-image-preview"
                                                        onLoad={() => {
                                                            setNoImage(false);
                                                            URL.revokeObjectURL(image.preview);
                                                        }}/>
                                                }
                                                <div className="preview-image-remove">
                                                    <button className="btn-image-remove"
                                                            onClick={removeImage}>
                                                       <svg xmlns="http://www.w3.org/2000/svg" version="1.1"  width="12" height="15" x="0" y="0" viewBox="0 0 329.269 329" ><g><g fill="#f44336"><path d="M21.34 329.398c-5.461 0-10.926-2.09-15.082-6.25-8.344-8.34-8.344-21.824 0-30.164L292.848 6.391c8.34-8.34 21.824-8.34 30.164 0 8.343 8.34 8.343 21.824 0 30.164L36.422 323.148a21.231 21.231 0 0 1-15.082 6.25zm0 0" fill="#f44336" opacity="1" data-original="#f44336" class=""></path><path d="M307.93 329.398c-5.461 0-10.922-2.09-15.082-6.25L6.258 36.555c-8.344-8.34-8.344-21.825 0-30.164 8.34-8.34 21.82-8.34 30.164 0l286.59 286.593c8.343 8.34 8.343 21.825 0 30.164-4.16 4.18-9.621 6.25-15.082 6.25zm0 0" fill="#f44336" opacity="1" data-original="#f44336" class=""></path></g></g></svg>
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <input {...getInputProps()}/>
                                </div>
                            </div>
                        </div>
                        {userError.error &&
                        <div className="alert alert-danger" role="alert">
                            {userError.errorMessage}
                        </div>}
                        {userSuccess.success &&
                        <div className="alert alert-success" role="alert">
                            {userSuccess.successMessage}
                        </div>}
                        <div className='submit-buttons-user-data'>
                            <button type="reset" className='cancel-update' onClick={() => {
                                if (user.photo) {
                                    setNoImage(false);
                                }
                                setImage(null);
                            }}>Cancel
                            </button>
                            <button type="submit" className='do-update'>
                                {userLoading ?
                                    <span className="spinner-grow spinner-grow-sm" role="status"
                                          aria-hidden="true"/>
                                    : null
                                }
                                Update Details
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <div className='uerSetting-personal-section series-container sec-2-user-settings'>
                <div className='left-side-us'>
                    <span className='main-personal-ttl'>Reset password</span>
                    <span className='sub-personal-ttl'>Please enter your current password to change your password</span>
                </div>
                <div className='right-side-us'>
                    <form className='user-data-page-form' onSubmit={handleUpdatePassword}>
                        <div className='single-div-class'>
                            <div>
                                <label className='us-form-label'>Password</label>
                                <div className='wrapper-icon-div-input'>
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        id="password"
                                        name="password"
                                        placeholder="Current Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        className={passwordError ? 'input-error' : ''}
                                    />
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17"
                                         fill="none" onClick={togglePasswordVisibility}>
                                        <path
                                            d="M14.1796 6.6215C13.9863 6.31483 13.7796 6.02816 13.5663 5.7615C13.3196 5.44816 12.853 5.4215 12.573 5.7015L10.573 7.7015C10.7196 8.1415 10.7463 8.64816 10.613 9.17483C10.3796 10.1148 9.61964 10.8748 8.67964 11.1082C8.15297 11.2415 7.64631 11.2148 7.20631 11.0682C7.20631 11.0682 6.25297 12.0215 5.56631 12.7082C5.23297 13.0415 5.33964 13.6282 5.78631 13.8015C6.49964 14.0748 7.23964 14.2148 7.99964 14.2148C9.18631 14.2148 10.3396 13.8682 11.393 13.2215C12.4663 12.5548 13.433 11.5748 14.213 10.3282C14.8463 9.3215 14.813 7.62817 14.1796 6.6215Z"
                                            fill="#CCCCCC"/>
                                        <path
                                            d="M9.34708 7.15375L6.65375 9.84708C6.31375 9.50042 6.09375 9.02042 6.09375 8.50042C6.09375 7.45375 6.94708 6.59375 8.00042 6.59375C8.52042 6.59375 9.00042 6.81375 9.34708 7.15375Z"
                                            fill="#CCCCCC"/>
                                        <path
                                            d="M12.1663 4.33182L9.90634 6.59182C9.41967 6.09849 8.74634 5.80516 7.99967 5.80516C6.50634 5.80516 5.30634 7.01182 5.30634 8.49849C5.30634 9.24516 5.60634 9.91849 6.09301 10.4052L3.83967 12.6652H3.83301C3.09301 12.0652 2.41301 11.2985 1.83301 10.3918C1.16634 9.34516 1.16634 7.64516 1.83301 6.59849C2.60634 5.38516 3.55301 4.43182 4.60634 3.77849C5.65967 3.13849 6.81301 2.78516 7.99967 2.78516C9.48634 2.78516 10.9263 3.33182 12.1663 4.33182Z"
                                            fill="#CCCCCC"/>
                                        <path
                                            d="M9.90672 8.50005C9.90672 9.54672 9.05339 10.4067 8.00005 10.4067C7.96005 10.4067 7.92672 10.4067 7.88672 10.3934L9.89339 8.38672C9.90672 8.42672 9.90672 8.46005 9.90672 8.50005Z"
                                            fill="#CCCCCC"/>
                                        <path
                                            d="M14.5136 1.98594C14.3136 1.78594 13.9869 1.78594 13.7869 1.98594L1.48691 14.2926C1.28691 14.4926 1.28691 14.8193 1.48691 15.0193C1.58691 15.1126 1.71358 15.1659 1.84691 15.1659C1.98025 15.1659 2.10691 15.1126 2.20691 15.0126L14.5136 2.70594C14.7202 2.50594 14.7202 2.18594 14.5136 1.98594Z"
                                            fill="#CCCCCC"/>
                                    </svg>
                                </div>
                                {passwordError && <p className="error-text">{passwordError}</p>}
                            </div>
                        </div>
                        <div className='single-div-class'>
                            <label className='us-form-label'>New Password</label>
                            <div className='wrapper-icon-div-input'>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    id="new_password"
                                    name="new_password"
                                    placeholder="New Password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    className={newPasswordError ? 'input-error' : ''}
                                />
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17"
                                     fill="none" onClick={togglePasswordVisibility}>
                                    <path
                                        d="M14.1796 6.6215C13.9863 6.31483 13.7796 6.02816 13.5663 5.7615C13.3196 5.44816 12.853 5.4215 12.573 5.7015L10.573 7.7015C10.7196 8.1415 10.7463 8.64816 10.613 9.17483C10.3796 10.1148 9.61964 10.8748 8.67964 11.1082C8.15297 11.2415 7.64631 11.2148 7.20631 11.0682C7.20631 11.0682 6.25297 12.0215 5.56631 12.7082C5.23297 13.0415 5.33964 13.6282 5.78631 13.8015C6.49964 14.0748 7.23964 14.2148 7.99964 14.2148C9.18631 14.2148 10.3396 13.8682 11.393 13.2215C12.4663 12.5548 13.433 11.5748 14.213 10.3282C14.8463 9.3215 14.813 7.62817 14.1796 6.6215Z"
                                        fill="#CCCCCC"/>
                                    <path
                                        d="M9.34708 7.15375L6.65375 9.84708C6.31375 9.50042 6.09375 9.02042 6.09375 8.50042C6.09375 7.45375 6.94708 6.59375 8.00042 6.59375C8.52042 6.59375 9.00042 6.81375 9.34708 7.15375Z"
                                        fill="#CCCCCC"/>
                                    <path
                                        d="M12.1663 4.33182L9.90634 6.59182C9.41967 6.09849 8.74634 5.80516 7.99967 5.80516C6.50634 5.80516 5.30634 7.01182 5.30634 8.49849C5.30634 9.24516 5.60634 9.91849 6.09301 10.4052L3.83967 12.6652H3.83301C3.09301 12.0652 2.41301 11.2985 1.83301 10.3918C1.16634 9.34516 1.16634 7.64516 1.83301 6.59849C2.60634 5.38516 3.55301 4.43182 4.60634 3.77849C5.65967 3.13849 6.81301 2.78516 7.99967 2.78516C9.48634 2.78516 10.9263 3.33182 12.1663 4.33182Z"
                                        fill="#CCCCCC"/>
                                    <path
                                        d="M9.90672 8.50005C9.90672 9.54672 9.05339 10.4067 8.00005 10.4067C7.96005 10.4067 7.92672 10.4067 7.88672 10.3934L9.89339 8.38672C9.90672 8.42672 9.90672 8.46005 9.90672 8.50005Z"
                                        fill="#CCCCCC"/>
                                    <path
                                        d="M14.5136 1.98594C14.3136 1.78594 13.9869 1.78594 13.7869 1.98594L1.48691 14.2926C1.28691 14.4926 1.28691 14.8193 1.48691 15.0193C1.58691 15.1126 1.71358 15.1659 1.84691 15.1659C1.98025 15.1659 2.10691 15.1126 2.20691 15.0126L14.5136 2.70594C14.7202 2.50594 14.7202 2.18594 14.5136 1.98594Z"
                                        fill="#CCCCCC"/>
                                </svg>
                            </div>
                            {newPasswordError && <p className="error-text">{newPasswordError}</p>}
                        </div>
                        <div className='single-div-class'>
                            <label className='us-form-label'>Confirm Password</label>
                            <div className='wrapper-icon-div-input'>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    id="confirm_password"
                                    name="confirm_password"
                                    placeholder="Confirm Password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    className={confirmPasswordError ? 'input-error' : ''}
                                />
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17"
                                     fill="none" onClick={togglePasswordVisibility}>
                                    <path
                                        d="M14.1796 6.6215C13.9863 6.31483 13.7796 6.02816 13.5663 5.7615C13.3196 5.44816 12.853 5.4215 12.573 5.7015L10.573 7.7015C10.7196 8.1415 10.7463 8.64816 10.613 9.17483C10.3796 10.1148 9.61964 10.8748 8.67964 11.1082C8.15297 11.2415 7.64631 11.2148 7.20631 11.0682C7.20631 11.0682 6.25297 12.0215 5.56631 12.7082C5.23297 13.0415 5.33964 13.6282 5.78631 13.8015C6.49964 14.0748 7.23964 14.2148 7.99964 14.2148C9.18631 14.2148 10.3396 13.8682 11.393 13.2215C12.4663 12.5548 13.433 11.5748 14.213 10.3282C14.8463 9.3215 14.813 7.62817 14.1796 6.6215Z"
                                        fill="#CCCCCC"/>
                                    <path
                                        d="M9.34708 7.15375L6.65375 9.84708C6.31375 9.50042 6.09375 9.02042 6.09375 8.50042C6.09375 7.45375 6.94708 6.59375 8.00042 6.59375C8.52042 6.59375 9.00042 6.81375 9.34708 7.15375Z"
                                        fill="#CCCCCC"/>
                                    <path
                                        d="M12.1663 4.33182L9.90634 6.59182C9.41967 6.09849 8.74634 5.80516 7.99967 5.80516C6.50634 5.80516 5.30634 7.01182 5.30634 8.49849C5.30634 9.24516 5.60634 9.91849 6.09301 10.4052L3.83967 12.6652H3.83301C3.09301 12.0652 2.41301 11.2985 1.83301 10.3918C1.16634 9.34516 1.16634 7.64516 1.83301 6.59849C2.60634 5.38516 3.55301 4.43182 4.60634 3.77849C5.65967 3.13849 6.81301 2.78516 7.99967 2.78516C9.48634 2.78516 10.9263 3.33182 12.1663 4.33182Z"
                                        fill="#CCCCCC"/>
                                    <path
                                        d="M9.90672 8.50005C9.90672 9.54672 9.05339 10.4067 8.00005 10.4067C7.96005 10.4067 7.92672 10.4067 7.88672 10.3934L9.89339 8.38672C9.90672 8.42672 9.90672 8.46005 9.90672 8.50005Z"
                                        fill="#CCCCCC"/>
                                    <path
                                        d="M14.5136 1.98594C14.3136 1.78594 13.9869 1.78594 13.7869 1.98594L1.48691 14.2926C1.28691 14.4926 1.28691 14.8193 1.48691 15.0193C1.58691 15.1126 1.71358 15.1659 1.84691 15.1659C1.98025 15.1659 2.10691 15.1126 2.20691 15.0126L14.5136 2.70594C14.7202 2.50594 14.7202 2.18594 14.5136 1.98594Z"
                                        fill="#CCCCCC"/>
                                </svg>
                            </div>
                            {confirmPasswordError && <p className="error-text">{confirmPasswordError}</p>}
                        </div>
                        {error.error &&
                        <div className="alert alert-danger" role="alert">
                            {error.errorMessage}
                        </div>}
                        {success.success &&
                        <div className="alert alert-success" role="alert">
                            {success.successMessage}
                        </div>}
                        <div className='submit-buttons-user-data'>
                            <button type="button" className='cancel-update'>Cancel</button>
                            <button type="submit" className='do-update'>
                                {loading ?
                                    <span className="spinner-grow spinner-grow-sm" role="status"
                                          aria-hidden="true"/>
                                    : null
                                }
                                Update Password
                            </button>
                        </div>
                    </form>

                </div>
            </div>

            <div className='uerSetting-personal-section series-container sec-2-user-settings'>
                <div className='left-side-us'>
                    <span className='main-personal-ttl'>Terms & conditions</span>
                    <span className='sub-personal-ttl'>View all terms & conditions and disclaimers</span>
                </div>
                <div className='right-side-us'>
                    {loadingContent ?
                        <Loading/>
                        : legals.map((legal, index) => (
                            <div className="faq-item" key={"Legal" + index}>
                                <div
                                    className={`faq-question ${activeIndex === index ? 'open' : ''}`}
                                    onClick={() => toggleFAQ(index)}
                                >
                                    {legal.heading}
                                    <span className="arrow">
                                        {activeIndex === index ?
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24"
                                                 fill="none">
                                                <path
                                                    d="M19.9201 15.05L13.4001 8.53005C12.6301 7.76005 11.3701 7.76005 10.6001 8.53005L4.08008 15.05"
                                                    stroke="#25242F" strokeWidth="1.5" strokeMiterlimit="10"
                                                    strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg> :
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24"
                                                 fill="none">
                                                <path
                                                    d="M19.9201 8.94995L13.4001 15.47C12.6301 16.24 11.3701 16.24 10.6001 15.47L4.08008 8.94995"
                                                    stroke="#25242F" strokeWidth="1.5" strokeMiterlimit="10"
                                                    strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        }
                                    </span>
                                </div>
                                {activeIndex === index && (
                                    <div className="faq-answer textarea-description">
                                        <div id={"editor" + index}/>
                                    </div>
                                )}
                            </div>
                        ))}
                </div>
            </div>
        </div>
    )
}
