import React, {useEffect, useState} from 'react'
import '../assets/css/ContactUs.css';
import '../assets/css/Series.css';
import phone from "../assets/images/phone.svg";
import sms from "../assets/images/sms.svg";
import contactbanner from "../assets/images/contactbanner.jpg";
import {loadContactDetails, loadCTABanner} from "../common/Common";
import Loading from "../components/Loading";
import CTABanner from "../components/CTABanner";
import {postRequest} from "../routes/Routes";

function ContactUs() {
    const [ctaBanner, setCtaBanner] = useState(null),
        [contacts, setContacts] = useState(null),
        [loading, setLoading] = useState(true),
        [loadingSubmit, setLoadingSubmit] = useState(false),
        [error, setError] = useState({}),
        [success, setSuccess] = useState({});

    useEffect(() => {
        loadCTABanner("contact us").then((data) => {
            setCtaBanner(data);
            setLoading(false);
        }).catch(() => null);
        loadContactDetails().then((data) => {
            setContacts(data);
        }).catch(() => null);
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoadingSubmit(true);
        let values = {
            first_name: event.target.first_name.value,
            last_name: event.target.last_name.value,
            phone: event.target.phone.value,
            email: event.target.email.value,
            message: event.target.message.value,
            endpoint: '/api/contact-us'
        };
        postRequest(values).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    setError(values => ({...values, error: true, errorMessage: response.data.message}));
                } else {
                    setError(values => ({...values, error: false, errorMessage: null}));
                    setSuccess(values => ({...values, success: true, successMessage: response.data.message}));
                }
            } else {
                setError(values => ({...values, error: true, errorMessage: "An error occurred!"}));
            }
            setLoadingSubmit(false);
        });
    };

    if (!loading) {
        return (
            <div className='contact-main body-main'>
                <div className='contact-container'>
                    <div className='top-banner-default'>
                        <div className='text-section series-container'>
                        <span className='bdc-txt '>
                            Home / Connect
                        </span>
                            <span href="" className='mani-pg-name'>Connect</span>
                        </div>
                        <div className='abs-slider-images'>
                            <picture>
                                <source media="(max-width: 767px)" srcset={contactbanner} alt="/"/>
                                <source media="(max-width: 1023px)" srcset={contactbanner} alt=""/>
                                <img src={contactbanner} alt="background"/>
                            </picture>
                        </div>
                    </div>

                    <div className='section-2-contact series-container'>
                        <form onSubmit={handleSubmit} className='section-2-contact-details'>
                            <div className='topic'>
                                <span>If you have any questions, send us a<br/>quick message and we’ll get back to you shortly!</span>
                                <hr/>
                            </div>

                            <div className='details-main'>
                                <div className='details-sub'>
                                    <div>
                                        <label>First Name</label>
                                    </div>
                                    <div>
                                        <input className="contact-input" id="first_name" name="first_name"
                                               type="text"
                                               placeholder="Your First Name"
                                               required/>
                                    </div>
                                </div>
                                <div className='details-sub'>
                                    <div>
                                        <label>Last Name</label>
                                    </div>
                                    <input className="contact-input" id="last_name" name="last_name"
                                           type="text"
                                           placeholder="Your Last Name"
                                           required/>
                                </div>
                            </div>
                            <div className='details-main'>
                                <div className='details-sub'>
                                    <div>
                                        <label>Mobile Phone</label>
                                    </div>
                                    <input className="contact-input" id="phone" name="phone"
                                           type="tel"
                                           placeholder="+94|00 - 000 - 0000"
                                           required/>
                                </div>

                                <div className='details-sub'>
                                    <div>
                                        <label>Email Address</label>
                                    </div>
                                    <input className="contact-input" id="email" name="email"
                                           type="email"
                                           placeholder="Your Email Address"
                                           required/>
                                </div>
                            </div>
                            <div className='details-main'>
                                <div className='details-sub'>
                                    <div>
                                        <label>Message</label>
                                    </div>
                                    <textarea className="contact-input-textarea" id="message"
                                              name="message"
                                              placeholder="Type message here..."
                                              required/>
                                </div>
                            </div>
                            {error.error &&
                            <div className="alert alert-danger" role="alert">
                                {error.errorMessage}
                            </div>}
                            {success.success &&
                            <div className="alert alert-success" role="alert">
                                {success.successMessage}
                            </div>}
                            <div>
                                <button type="submit" className='section-2-contact-button'>
                                    {loadingSubmit ?
                                        <span className="spinner-grow spinner-grow-sm" role="status"
                                              aria-hidden="true"/>
                                        : null
                                    }
                                    Send Message
                                </button>
                            </div>
                        </form>
                    </div>

                    <div className='section-3-contact'>
                        <div class="section-3-contact-wrapper">
                            <div className='section-2-topic-details'>
                                <span className='topic'>Send us A Message. We are<br/> always happy to help!</span>
                            </div>
                            <div className='details-main'>
                                {/* <div className='details-sub'>
                                    <div>
                                        <img src={phone} alt='phone'/>
                                    </div>
                                    <div className='details'>
                                        <span>Phone Number:</span><br/>
                                        <span><a href='tel:0894863800 '> 0894863800 </a></span>
                                    </div>
                                </div>
                                <div className='details-sub'>
                                    <div>
                                        <img src={sms} alt='sms'/>
                                    </div>
                                    <div className='details'>
                                        <span>Email Address:</span><br/>
                                        <span><a href='mailto:contact@pilates-corner.com'>contact@pilates-corner.com</a></span>
                                    </div>
                                </div> */}
                                {contacts && contacts.length > 0 ? (
                                    contacts.map((contact) => (
                                        <React.Fragment key={contact.id}>
                                            {contact.value.includes('@') ? (
                                                <div className='details-sub'>
                                                    <div>
                                                        <img src={sms} alt='sms'/>
                                                    </div>
                                                    <div className='details'>
                                                        <span>{contact.title}</span><br/>
                                                        <span><a href={`mailto:${contact.value}`}>{contact.value}</a></span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='details-sub'>
                                                    <div>
                                                        <img src={phone} alt='phone'/>
                                                    </div>
                                                    <div className='details'>
                                                        <span>{contact.title}</span><br/>
                                                        <span><a href={`tel:${contact.value}`}>{contact.value}</a></span>
                                                    </div>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    ))
                                ) : (
                                    <li>No contact details available.</li>
                                )}
                            </div>
                        </div>
                    </div>
                    {ctaBanner &&
                    <CTABanner ctaBanner={ctaBanner}/>
                    }
                </div>
            </div>
        )
    } else {
        return (
            <Loading/>
        );
    }
}

export default ContactUs
