import React, {useState} from 'react';
import {Link} from 'react-router-dom';

import forgetpassword from "../assets/images/forgetpassword.jpg";
import '../assets/css/Login.css';
import {postRequest} from "../routes/Routes";
import logo from "../assets/images/logo-dark.png";

function ForgotPassword() {
    const [email, setEmail] = useState(''),
        [emailError, setEmailError] = useState(''),
        [loading, setLoading] = useState(false),
        [error, setError] = useState({}),
        [success, setSuccess] = useState({});

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const handleForgetPassword = (event) => {
        event.preventDefault();
        let valid = true;

        if (!validateEmail(email)) {
            setEmailError('Invalid email address. Please try again.');
            valid = false;
        } else {
            setEmailError('');
        }

        if (valid) {
            setLoading(true);
            let values = {
                email: event.target.email.value,
                endpoint: '/api/forgot-password'
            };
            postRequest(values).then(response => {
                if (response.status === 200) {
                    if (response.data.error) {
                        setError(values => ({...values, error: true, errorMessage: response.data.message}));
                    } else {
                        setError({});
                        setSuccess(values => ({...values, success: true, successMessage: response.data.message}));
                    }
                } else {
                    setError(values => ({...values, error: true, errorMessage: "An error occurred!"}));
                }
                setLoading(false);
            });
        }
    };

    return (
        <>
            <div className=' body-main login-flex login-container'>
                <div className="login-container left-login-div">
                    <a href="">
                        <img src={logo} alt='Logo'/>
                    </a>
                    <h2 className='welcome-back-tag'>Forgot Password</h2>
                    <span className='welcome-sub-tag'>Enter the email address associated with your account.</span>
                    <form onSubmit={handleForgetPassword}>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <div className='wrp-login'>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Your Email Address"
                                    className={emailError ? 'input-error' : ''}
                                />
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17"
                                     fill="none">
                                    <path
                                        d="M11.3335 2.83203H4.66683C2.66683 2.83203 1.3335 3.83203 1.3335 6.16536V10.832C1.3335 13.1654 2.66683 14.1654 4.66683 14.1654H11.3335C13.3335 14.1654 14.6668 13.1654 14.6668 10.832V6.16536C14.6668 3.83203 13.3335 2.83203 11.3335 2.83203ZM11.6468 6.89203L9.56016 8.5587C9.12016 8.91203 8.56016 9.08536 8.00016 9.08536C7.44016 9.08536 6.8735 8.91203 6.44016 8.5587L4.3535 6.89203C4.14016 6.7187 4.10683 6.3987 4.2735 6.18536C4.44683 5.97203 4.76016 5.93203 4.9735 6.10536L7.06016 7.77203C7.56683 8.1787 8.42683 8.1787 8.9335 7.77203L11.0202 6.10536C11.2335 5.93203 11.5535 5.96536 11.7202 6.18536C11.8935 6.3987 11.8602 6.7187 11.6468 6.89203Z"
                                        fill="#CCCCCC"/>
                                </svg>
                            </div>
                            {emailError && <p className="error-text">{emailError}</p>}
                        </div>
                        {error.error &&
                        <div className="alert alert-danger" role="alert">
                            {error.errorMessage}
                        </div>}
                        {success.success &&
                        <div className="alert alert-success" role="alert">
                            {success.successMessage}
                        </div>}
                        <button type="submit" className="login-button">
                            {loading ?
                                <span className="spinner-grow spinner-grow-sm" role="status"
                                      aria-hidden="true"/>
                                : null
                            }
                            Reset Password
                        </button>
                    </form>
                    <div className="login-footer">
                        <p>Don’t have an account? <Link to="/signup">Sign Up here</Link></p>
                    </div>
                </div>
                <div className="login-container right-login-div">
                    <img src={forgetpassword}/>
                    <div className='login-banner-abs-text'>
                        Explore 60+ Exciting Classes! Discover 2 New Ones Every Week.
                    </div>
                </div>
            </div>

        </>
    );
}

export default ForgotPassword;
