import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import '../assets/css/header.css';
import {loadLimitedAnnouncements} from "../common/Common";
import {deleteUser, getUser} from "../auth/Auth";
import userProfile from "../assets/images/user.png";
import logo from "../assets/images/logo-white.png";
import {postRequest} from "../routes/Routes";

function Header() {
    const [announcement, setAnnouncement] = useState(null),
        [user, setUser] = useState(undefined),
        [isOpen, setIsOpen] = useState(false),
        [loading, setLoading] = useState(false),
        navigate = useNavigate();

    useEffect(() => {
        getUser(false).then((data) => {
            setUser(data === undefined ? null : data);
        }).catch(() => {
            setUser(null);
        });
        loadLimitedAnnouncements(1).then((data) => {
            setAnnouncement(data.length > 0 ? data[0] : null);
        }).catch(() => null);
    }, []);

    useEffect(() => {
        const sidebar = document.getElementById("sidebar");
        const menuButton = document.getElementById("menu-button");
        const closeButton = document.getElementById("close-button");

        function openMenu() {
            sidebar.style.display = "flex";
            sidebar.style.animation = "sidebarIn 1s 0s forwards";
        }

        function closeMenu() {
            sidebar.style.animation = "sidebarOut 1s 0s forwards";
            setTimeout(() => {
                sidebar.style.display = "none";
            }, 500);
        }

        menuButton.addEventListener("click", openMenu);
        closeButton.addEventListener("click", closeMenu);
        const links = sidebar.querySelectorAll(".mobile-item-menu .header-link");
        links.forEach(link => {
            link.addEventListener("click", closeMenu);
        });

        return () => {
            menuButton.removeEventListener("click", openMenu);
            closeButton.removeEventListener("click", closeMenu);
            links.forEach(link => {
                link.removeEventListener("click", closeMenu);
            });
        };
    }, []);

    const [headerPadding, setHeaderPadding] = useState('48px');

    const handleNavigation = (path, event) => {
        event.preventDefault(); // Prevent the default link action
        navigate(path);         // Navigate to the provided path
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setHeaderPadding('48px');
            } else {
                setHeaderPadding(window.innerWidth <= 768 ? '48px' : '48px');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleLogout = () => {
        setLoading(true);
        let values = {
            endpoint: '/api/logout',
            token: user.token,
        };
        postRequest(values).then(response => {
            if (response.status === 200) {
                deleteUser().then(() => {
                    window.location.href = '/login';
                });
            }
            setLoading(false);
        });
    };

    return (
        <>
            {announcement &&
            <div className='yellow-class'>
                {announcement.details.split(" ").map((item, index) => (
                    <>
                        {item === announcement.link_text ?
                            <>
                                <a href={announcement.link} target="_blank" style={{textDecoration: "underline"}}>
                                    {announcement.link_text}
                                </a>
                            </>
                            : <>{item} </>
                        }
                    </>
                ))}
            </div>
            }
            <header style={{ paddingTop: headerPadding }}>
                <div className='container header-container'>
                    <Link className="logo-link" to="/">
                        <div className="logo-box">
                            <div className="logo">
                                <img src={logo} alt='Logo'/>
                            </div>
                        </div>
                    </Link>

                    <nav>
                        <Link className="header-link" onClick={(e) => handleNavigation('/series', e)} to="/series">Classes</Link>
                        <Link className="header-link" onClick={(e) => handleNavigation('/courses', e)} to="/courses">Courses</Link>
                        <Link className="header-link" onClick={(e) => handleNavigation('/contact-us', e)} to="/contact-us">Connect</Link>
                        <Link className="header-link" onClick={(e) => handleNavigation('/about-us', e)} to="/about-us">About</Link>
                        <Link className="header-link" onClick={(e) => handleNavigation('/how-to', e)} to="/how-to">How to</Link>
                        <Link className="header-link" onClick={(e) => handleNavigation('/price', e)} to="/price">Subscriptions</Link>
                        {user ?
                            <span className={`header-link login-signup ${user ? 'no-background' : ''}`} >
                                <div className='login-signup-photo-name' onClick={toggleDropdown}>
                                    <img src={user.photo ? user.photo : userProfile} alt='user photo'/>
                                    <p>Hi, {user.first_name}

                                    </p>
                                    <svg width="24" height="24" x="0" y="0" viewBox="0 0 128 128" enable-background="new 0 0 512 512">
                                        <g>
                                            <path d="M64 88a3.988 3.988 0 0 1-2.828-1.172l-40-40c-1.563-1.563-1.563-4.094 0-5.656s4.094-1.563 5.656 0L64 78.344l37.172-37.172c1.563-1.563 4.094-1.563 5.656 0s1.563 4.094 0 5.656l-40 40A3.988 3.988 0 0 1 64 88z" fill="#fff" opacity="1" />
                                        </g>
                                    </svg>

                                    {/* <img src={downarr} alt="toggler" className='down-ico-hdr'/> */}
                                </div>
                                {isOpen && (
                                    <div className="dropdown-menu">
                                        <Link onClick={(e) => handleNavigation('/profile', e)} to="/profile">
                                            <button className="dropdown-item">View Profile</button>
                                        </Link>
                                        <button className="dropdown-item" onClick={handleLogout}>
                                            {loading ?
                                                <span className="spinner-grow spinner-grow-sm" role="status"
                                                      aria-hidden="true"/>
                                                : null
                                            }
                                            Logout
                                        </button>
                                    </div>
                                )}
                            </span>

                            : <Link className="header-link login-signup" onClick={(e) => handleNavigation('/login', e)} to="/login">Login / Signup</Link>
                        }
                    </nav>

                    <button className="menu-button" id="menu-button">
                        <i className="bi bi-list"/>
                    </button>
                </div>
            </header>

            <div className="sidebar" id="sidebar">
                <div className="sidebar-header">
                    <span className="sidebar-title">Menu</span>
                    <button className="close-button" id="close-button">
                        <i className="bi bi-x-lg" />
                    </button>
                </div>
                <div className='mobile-item-menu'>
                    <Link className="header-link" onClick={(e) => handleNavigation('/series', e)} to="/series">Classes</Link>
                    <Link className="header-link" onClick={(e) => handleNavigation('/courses', e)} to="/courses">Courses</Link>
                    <Link className="header-link" onClick={(e) => handleNavigation('/contact-us', e)} to="/contact-us">Connect</Link>
                    <Link className="header-link" onClick={(e) => handleNavigation('/about-us', e)} to="/about-us">About</Link>
                    <Link className="header-link" onClick={(e) => handleNavigation('/how-to', e)} to="/how-to">How to</Link>
                    <Link className="header-link" onClick={(e) => handleNavigation('/price', e)} to="/price">Subscriptions</Link>
                </div>
                {user ?
                    <div className='login-sgn-mobile'>
                        <div className='flx-br'>
                            <div className='login-signup-photo-name' onClick={toggleDropdown}>
                                <img src={user.photo ? user.photo : userProfile} alt='user photo'/>
                                <p>Hi, {user.first_name}</p>
                            </div>
                            {isOpen && (
                                <div className="dropdown-menu">
                                    <Link onClick={(e) => handleNavigation('/profile', e)} to="/profile">
                                        <button className="dropdown-item">View Profile</button>
                                    </Link>
                                    <button className="dropdown-item" onClick={handleLogout}>
                                        {loading ?
                                            <span className="spinner-grow spinner-grow-sm" role="status"
                                                  aria-hidden="true"/>
                                            : null
                                        }
                                        Logout
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    : <div className='login-sgn-mobile'>
                        <div className='flx-br'>Don’t have an account? <Link onClick={(e) => handleNavigation('/signup', e)} to="/signup">Sign Up here</Link></div>
                        <div className='flx-br'>Already A member?<Link onClick={(e) => handleNavigation('/login', e)} to="/login"> Login here</Link></div>
                    </div>
                }
            </div>
        </>
    )
}

export default Header
