import React from 'react';
import '../assets/css/NotFound.css';
import image13 from "../assets/images/notfound.jpg";

export default function NotFound() {
    return (
        <div className='body-main'>
            <div className='not-f-main'>
                <div className='notfound-wrapper-abs'>
                    <span className='nf-abs-txt '> 404</span>
                    <span className='nf-abs-txt2 '> Oops! Page not found</span>
                    <span className='nf-abs-para '> We are very sorry for the inconvenience, but it seems like the page that you’re trying ot access is missing or you have assembled the link incorrectly.</span>
                    <a href="/" className='nf-pg-name'>Back To Home</a>
                </div>
                <div className='abs-nf-images'>
                    <picture>
                        <source media="(max-width: 767px)" srcset={image13} alt="/"/>
                        <source media="(max-width: 1023px)" srcset={image13} alt=""/>
                        <img src={image13} alt="background"/>
                    </picture>
                </div>
            </div>
        </div>
    )
}
