import React, { useEffect, useState } from 'react';
import { loadLegals, loadContactDetails } from "../common/Common";
import { Link, useLocation } from "react-router-dom";
import '../assets/css/Footer.css';
import logo from "../assets/images/logo-dark.png";
import {postRequest} from "../routes/Routes";

function Footer() {
    const location = useLocation();
    const [contacts, setContacts] = useState(null);
    const [legals, setLegals] = useState([]);
    const [error, setError] = useState({});
    const [success, setSuccess] = useState({});
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const currentYear = new Date().getFullYear();

    useEffect(() => {
        loadLegals().then((data) => {
            setLegals(data);
        }).catch(() => null);
        loadContactDetails().then((data) => {
            setContacts(data);
        }).catch(() => null);
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoadingSubmit(true);
        let values = {
            first_name: event.target.first_name.value,
            email: event.target.email.value,
            message: event.target.message.value,
            endpoint: '/api/contact-us'
        };
        postRequest(values).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    setError(values => ({...values, error: true, errorMessage: response.data.message}));
                } else {
                    setError(values => ({...values, error: false, errorMessage: null}));
                    setSuccess(values => ({...values, success: true, successMessage: response.data.message}));
                }
            } else {
                setError(values => ({...values, error: true, errorMessage: "An error occurred!"}));
            }
            setLoadingSubmit(false);
        });
    };

    return (
        <div className='main-container-footer'>
            {location.pathname !== "/contact-us" && (
                <div className='section-main container-footer'>
                    <div className='section-1 logo'>
                        <h2>Get in touch</h2>
                        <h4 className='main-intro-footer'>
                            Email Aaron at <a href='mailto:contact@pilates-corner.com'>contact@pilates-corner.com</a> or fill out the contact form here and we will answer any of your queries.
                        </h4>
                    </div>
                    <div className='section-1 content'>
                        <div className='section-1-footer-contact'>
                            <form onSubmit={handleSubmit}>
                                <div className='details-main' style={{ display: 'flex', gap: '20px' }}>
                                    <div className='details-sub'>
                                        <input
                                            className="contact-input content-input-ftr"
                                            id="first_name"
                                            name="first_name"
                                            placeholder="Full Name"
                                            required
                                        />
                                    </div>
                                    <div className='details-sub'>
                                        <input
                                            className="contact-input content-input-ftr"
                                            id="email"
                                            name="email"
                                            placeholder="Email Address"
                                            required
                                        />
                                    </div>
                                </div>

                                <div className='details-main details-main-ftr'>
                                    <div className='details-sub'>
                                        <textarea
                                            className="footer-contact-input-textarea"
                                            id="message"
                                            name="message"
                                            placeholder="Message..."
                                            required
                                        />
                                    </div>
                                    
                                    <div>
                                        <button type="submit" className='section-2-contact-button'>
                                            {loadingSubmit ?
                                                <span className="spinner-grow spinner-grow-sm" role="status"
                                                    aria-hidden="true"/>
                                                : null
                                            }
                                            Send Message
                                        </button>
                                    </div>
                                </div>
                                {error.error &&
                                    <div className="alert alert-danger" role="alert">
                                        {error.errorMessage}
                                    </div>}
                                    {success.success &&
                                    <div className="alert alert-success" role="alert">
                                        {success.successMessage}
                                    </div>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            )}

            <div className='section-main2 container-footer'>
                <img src={logo} alt='Logo' />
                <div className='section-2 links'>
                    <h5>Quick Links</h5>
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/series">Classes</Link></li>
                        <li><Link to="/courses">Courses</Link></li>
                        <li><Link to="/contact-us">Connect</Link></li>
                        <li><Link to="/about-us">About</Link></li>
                    </ul>
                </div>
                <div className='section-2 legal'>
                    <h5>Terms & Conditions</h5>
                    <ul>
                        {legals.map((legal) => (
                            <li key={legal.id}>
                                <Link to={'/legal'}>{legal.heading}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className='section-2 contact con-detail'>
                    <h5>Contact Details</h5>
                    <ul>
                        {contacts && contacts.length > 0 ? (
                            contacts.map((contact) => (
                                <React.Fragment key={contact.id}>
                                    <li>
                                        {contact.title}
                                        <a 
                                            href={contact.value.includes('@') ? `mailto:${contact.value}` : `tel:${contact.value}`}
                                        >
                                            {contact.value}
                                        </a>
                                    </li>
                                </React.Fragment>
                            ))
                        ) : (
                            <li>No contact details available.</li>
                        )}
                    </ul>
                </div>
            </div>

            <div className='section-main3 container-footer'>
                <div className='section-3 term'>
                    © {currentYear} The Pilates Corner | All rights reserved
                </div>
                <div className='section-3 term'>
                    Designed & Developed by <a href="https://yellawt.com" target='_blank' style={{color:"unset", textDecoration:"none"}}>Yellawt</a>
                </div>
            </div>
        </div>
    );
}

export default Footer;
