import React, {useState} from 'react';
import LoggingBanner from "../assets/images/logging banner.jpg";
import '../assets/css/Login.css';
import {Link} from "react-router-dom";
import {createUser, deleteNewUser} from "../auth/Auth";
import {postRequest} from "../routes/Routes";
import logo from "../assets/images/logo-dark.png";

function UserLogin() {
    const [showPassword, setShowPassword] = useState(false),
        [loading, setLoading] = useState(false),
        [error, setError] = useState({}),
        [email, setEmail] = useState(''),
        [password, setPassword] = useState(''),
        [emailError, setEmailError] = useState(''),
        [passwordError, setPasswordError] = useState(''),
        [isChecked, setIsChecked] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const handleLogin = (event) => {
        event.preventDefault();
        let valid = true;

        if (!validateEmail(email)) {
            setEmailError('Invalid email address. Please try again.');
            valid = false;
        } else {
            setEmailError('');
        }

        if (password.length < 6) {
            setPasswordError('Password must contain at least 6 characters.');
            valid = false;
        } else {
            setPasswordError('');
        }

        if (valid) {
            setLoading(true);
            let values = {
                email: event.target.email.value,
                password: event.target.password.value,
                endpoint: '/api/login'
            };
            postRequest(values).then(response => {
                if (response.status === 200) {
                    if (response.data.error) {
                        setError(values => ({...values, error: true, errorMessage: response.data.message}));
                    } else {
                        createUser(response.data.user).then(() => {
                            deleteNewUser().then(null);
                            setError(values => ({...values, error: false, errorMessage: null}));
                            window.location.href = 'profile/';
                        });
                    }
                } else {
                    setError(values => ({...values, error: true, errorMessage: "An error occurred!"}));
                }
                setLoading(false);
            });
        }
    };

    return (
        <>
            <div className=' body-main login-flex login-container'>
                <div className="login-container left-login-div">
                    <a href="/">
                        <img src={logo} alt='Logo'/>
                    </a>
                    <h2 className='welcome-back-tag'>Welcome Back!</h2>
                    <span className='welcome-sub-tag'>Please enter your login credentials.</span>
                    <form onSubmit={handleLogin}>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <div className='wrp-login'>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Your Email Address"
                                    className={emailError ? 'input-error' : ''}
                                />
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17"
                                     fill="none">
                                    <path
                                        d="M11.3335 2.83203H4.66683C2.66683 2.83203 1.3335 3.83203 1.3335 6.16536V10.832C1.3335 13.1654 2.66683 14.1654 4.66683 14.1654H11.3335C13.3335 14.1654 14.6668 13.1654 14.6668 10.832V6.16536C14.6668 3.83203 13.3335 2.83203 11.3335 2.83203ZM11.6468 6.89203L9.56016 8.5587C9.12016 8.91203 8.56016 9.08536 8.00016 9.08536C7.44016 9.08536 6.8735 8.91203 6.44016 8.5587L4.3535 6.89203C4.14016 6.7187 4.10683 6.3987 4.2735 6.18536C4.44683 5.97203 4.76016 5.93203 4.9735 6.10536L7.06016 7.77203C7.56683 8.1787 8.42683 8.1787 8.9335 7.77203L11.0202 6.10536C11.2335 5.93203 11.5535 5.96536 11.7202 6.18536C11.8935 6.3987 11.8602 6.7187 11.6468 6.89203Z"
                                        fill="#CCCCCC"/>
                                </svg>
                            </div>
                            {emailError && <p className="error-text">{emailError}</p>}
                        </div>

                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <div className='wrp-login'>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    id="password"
                                    name="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Password"
                                    className={passwordError ? 'input-error' : ''}
                                />
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17"
                                     fill="none" onClick={togglePasswordVisibility}>
                                    <path
                                        d="M14.1796 6.6215C13.9863 6.31483 13.7796 6.02816 13.5663 5.7615C13.3196 5.44816 12.853 5.4215 12.573 5.7015L10.573 7.7015C10.7196 8.1415 10.7463 8.64816 10.613 9.17483C10.3796 10.1148 9.61964 10.8748 8.67964 11.1082C8.15297 11.2415 7.64631 11.2148 7.20631 11.0682C7.20631 11.0682 6.25297 12.0215 5.56631 12.7082C5.23297 13.0415 5.33964 13.6282 5.78631 13.8015C6.49964 14.0748 7.23964 14.2148 7.99964 14.2148C9.18631 14.2148 10.3396 13.8682 11.393 13.2215C12.4663 12.5548 13.433 11.5748 14.213 10.3282C14.8463 9.3215 14.813 7.62817 14.1796 6.6215Z"
                                        fill="#CCCCCC"/>
                                    <path
                                        d="M9.34708 7.15375L6.65375 9.84708C6.31375 9.50042 6.09375 9.02042 6.09375 8.50042C6.09375 7.45375 6.94708 6.59375 8.00042 6.59375C8.52042 6.59375 9.00042 6.81375 9.34708 7.15375Z"
                                        fill="#CCCCCC"/>
                                    <path
                                        d="M12.1663 4.33182L9.90634 6.59182C9.41967 6.09849 8.74634 5.80516 7.99967 5.80516C6.50634 5.80516 5.30634 7.01182 5.30634 8.49849C5.30634 9.24516 5.60634 9.91849 6.09301 10.4052L3.83967 12.6652H3.83301C3.09301 12.0652 2.41301 11.2985 1.83301 10.3918C1.16634 9.34516 1.16634 7.64516 1.83301 6.59849C2.60634 5.38516 3.55301 4.43182 4.60634 3.77849C5.65967 3.13849 6.81301 2.78516 7.99967 2.78516C9.48634 2.78516 10.9263 3.33182 12.1663 4.33182Z"
                                        fill="#CCCCCC"/>
                                    <path
                                        d="M9.90672 8.50005C9.90672 9.54672 9.05339 10.4067 8.00005 10.4067C7.96005 10.4067 7.92672 10.4067 7.88672 10.3934L9.89339 8.38672C9.90672 8.42672 9.90672 8.46005 9.90672 8.50005Z"
                                        fill="#CCCCCC"/>
                                    <path
                                        d="M14.5136 1.98594C14.3136 1.78594 13.9869 1.78594 13.7869 1.98594L1.48691 14.2926C1.28691 14.4926 1.28691 14.8193 1.48691 15.0193C1.58691 15.1126 1.71358 15.1659 1.84691 15.1659C1.98025 15.1659 2.10691 15.1126 2.20691 15.0126L14.5136 2.70594C14.7202 2.50594 14.7202 2.18594 14.5136 1.98594Z"
                                        fill="#CCCCCC"/>
                                </svg>
                            </div>
                            {passwordError && <p className="error-text">{passwordError}</p>}
                        </div>

                        <div className="form-group-flx">
                            <div className=''>
                                <input
                                    type="checkbox"
                                    id="terms"
                                    checked={isChecked}
                                    onChange={(e) => setIsChecked(e.target.checked)}
                                    required
                                />
                            </div>
                            <label htmlFor="terms">
                                I agree to the <Link to="/legal">Terms & Conditions</Link> and <Link to="/legal">Privacy
                                Policy</Link>
                            </label>
                        </div>
                        {error.error &&
                        <div className="alert alert-danger" role="alert">
                            {error.errorMessage}
                        </div>}
                        <button type="submit" className="login-button">
                            {loading ?
                                <span className="spinner-grow spinner-grow-sm" role="status"
                                      aria-hidden="true"/>
                                : null
                            }
                            Login
                        </button>
                    </form>

                    <div className="login-footer">
                        <p>Don’t have an account? <Link to="/signup">Sign Up here</Link></p>
                        <p className='forgot-password-login'><Link to="/forgot-password">Forgot Password?</Link></p>
                    </div>
                </div>
                <div className="login-container right-login-div">
                    <img src={LoggingBanner}/>
                    <div className='login-banner-abs-text'>
                    Physiotherapist led Pilates at any time, anywhere!
                    </div>
                </div>
            </div>
        </>
    );
}

export default UserLogin;
