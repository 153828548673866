import React, {useEffect, useState} from 'react';
import '../assets/css/Legal.css';
import '../assets/css/HowTo.css';
import {loadLegals} from "../common/Common";
import Loading from "../components/Loading";
import EditorJS from '@editorjs/editorjs';
import {getEditorJSTools} from "../components/editor-constants";

let editor = undefined;

export default function Legal() {
    const [activeIndex, setActiveIndex] = useState(null),
        [legals, setLegals] = useState([]),
        [loading, setLoading] = useState(true);

    useEffect(() => {
        loadLegals().then((data) => {
            setLegals(data);
            setLoading(false);
        }).catch(() => null);
    }, []);

    const toggleFAQ = (index) => {
        editor = undefined;
        setActiveIndex(activeIndex === index ? null : index);
    };

    if (!loading) {
        // if (!editor && activeIndex !== null) {
        //     editor = new EditorJS({
        //         holder: 'editor' + activeIndex,
        //         tools: getEditorJSTools(null),
        //         readOnly: true,
        //         data: JSON.parse(legals[activeIndex].description),
        //     });
        // }

        if (!editor && activeIndex !== null) {
            try {
                const data = legals && legals[activeIndex]?.description
                    ? isValidJSON(legals[activeIndex].description)
                        ? JSON.parse(legals[activeIndex].description)
                        : { blocks: [{ type: 'paragraph', data: { text: legals[activeIndex].description } }] } // Fallback for plain text
                    : { blocks: [] };
                editor = new EditorJS({
                    holder: 'editor' + activeIndex,
                    tools: getEditorJSTools(null),
                    placeholder: 'Legal Description',
                    readOnly: true,
                    data: data,
                });
            } catch (error) {
                console.error("Error initializing the editor:", error);
            }
        }
        function isValidJSON(str) {
            try {
                JSON.parse(str);
                return true;
            } catch {
                return false;
            }
        }
        return (
            <div className='body-main'>
                <div className='section legal-content'>
                    <h1 className='legal-title'>terms & conditions</h1>
                    <p className='legal-info'>Thank you for visiting Pilates Corner. By accessing our website and 
                        participating in our Pilates classes, you agree to comply with the 
                        terms and conditions set forth below. Please read these terms carefully, 
                        as they govern your use of our platform and services.</p>
                </div>

                <div className='special-wrapper2-howto legal-special-bg'>
                    <div className='section-5-howto legal-special-color series-container'>
                        {legals.map((legal, index) => (
                            <div className="faq-item" key={"Legal" + index}>
                                <div
                                    className={`faq-question ${activeIndex === index ? 'open' : ''}`}
                                    onClick={() => toggleFAQ(index)}
                                >
                                    {legal.heading}
                                    <span className="arrow">
                                        {activeIndex === index ?
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24"
                                                 fill="none">
                                                <path
                                                    d="M19.9201 15.05L13.4001 8.53005C12.6301 7.76005 11.3701 7.76005 10.6001 8.53005L4.08008 15.05"
                                                    stroke="#25242F" strokeWidth="1.5" strokeMiterlimit="10"
                                                    strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg> :
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24"
                                                 fill="none">
                                                <path
                                                    d="M19.9201 8.94995L13.4001 15.47C12.6301 16.24 11.3701 16.24 10.6001 15.47L4.08008 8.94995"
                                                    stroke="#25242F" strokeWidth="1.5" strokeMiterlimit="10"
                                                    strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        }
                                    </span>
                                </div>
                                {activeIndex === index && (
                                    <div className="faq-answer textarea-description">
                                        <div id={"editor" + index}/>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <Loading/>
        );
    }
}
