import React, {useState} from 'react';
import '../assets/css/Profile.css';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import '../assets/css/Series.css';
import Loading from "./Loading";
import cs03 from "../assets/images/cs03.jpg";

export default function ProfileSeries({userPricingPackage}) {
    const [showAll, setShowAll] = useState(true);

    const seriesAll = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "10px",
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: true,
        prevArrow: <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70" fill="none">
            <circle cx="35" cy="35" r="35" transform="matrix(-1 0 0 1 70 0)" fill="#687362"/>
            <path
                d="M19.2929 35.7071C18.9024 35.3166 18.9024 34.6834 19.2929 34.2929L25.6569 27.9289C26.0474 27.5384 26.6805 27.5384 27.0711 27.9289C27.4616 28.3195 27.4616 28.9526 27.0711 29.3431L21.4142 35L27.0711 40.6569C27.4616 41.0474 27.4616 41.6805 27.0711 42.0711C26.6805 42.4616 26.0474 42.4616 25.6569 42.0711L19.2929 35.7071ZM51 36H20V34H51V36Z"
                fill="white"/>
        </svg>,
        nextArrow: <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70" fill="none">
            <circle cx="35" cy="35" r="35" fill="#687362"/>
            <path
                d="M50.7071 35.7071C51.0976 35.3166 51.0976 34.6834 50.7071 34.2929L44.3431 27.9289C43.9526 27.5384 43.3195 27.5384 42.9289 27.9289C42.5384 28.3195 42.5384 28.9526 42.9289 29.3431L48.5858 35L42.9289 40.6569C42.5384 41.0474 42.5384 41.6805 42.9289 42.0711C43.3195 42.4616 43.9526 42.4616 44.3431 42.0711L50.7071 35.7071ZM19 36H50V34H19V36Z"
                fill="white"/>
        </svg>,
        responsive: [
            {
                breakpoint: 1025, // Below 768px screen width
                settings: {
                    slidesToShow: 3, // Show 1 slide at a time
                    slidesToScroll: 1,
                    centerMode: true, // Optional: Turn off centering for mobile
                    centerPadding: "20px", // Optional: Remove padding on mobile
                    dots: false,
                    arrows: true,
                }
            },{
                breakpoint: 600, 
                settings: {
                    slidesToShow: 1, 
                    slidesToScroll: 1,
                    centerMode: true, 
                    centerPadding: "10px", 
                    dots: true
                }
            }
            ,{
                breakpoint: 1000, 
                settings: {
                    slidesToShow: 2, 
                    slidesToScroll: 1,
                    centerMode: true, 
                    centerPadding: "10px", 
                    dots: true
                }
            }
        ]
    };

    const classList = (classes) => {
        let body = [];
        classes.forEach((classData, index) => {
            body.push(
                <div className='series-slider-content-details' key={classData.name + "" + classData.id + "" + index}>
                    <div className='series-slider-home-image'>
                        <iframe src={classData.link} frameBorder="0"
                                allow="fullscreen; muted"/>
                    </div>
                    <div className='series-slider-content-details-topic series-main-data'>
                        <h3>{classData.name}</h3>
                        <p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                 viewBox="0 0 16 16" fill="none">
                                <path
                                    d="M14.6666 8.00016C14.6666 11.6802 11.6799 14.6668 7.99992 14.6668C4.31992 14.6668 1.33325 11.6802 1.33325 8.00016C1.33325 4.32016 4.31992 1.3335 7.99992 1.3335C11.6799 1.3335 14.6666 4.32016 14.6666 8.00016Z"
                                    stroke="#9D9D9D" strokeWidth="1.5" strokeLinecap="round"
                                    strokeLinejoin="round"/>
                                <path
                                    d="M10.4734 10.1202L8.40675 8.88684C8.04675 8.6735 7.75342 8.16017 7.75342 7.74017V5.00684"
                                    stroke="#9D9D9D" strokeWidth="1.5" strokeLinecap="round"
                                    strokeLinejoin="round"/>
                            </svg>
                            {classData.time} minutes
                        </p>
                    </div>
                </div>
            );
        });
        return body;
    }

    const allClassesList = (classes) => {
        let body = [];
        classes.forEach((classData, index) => {
            if (!userPricingPackage.pricing_package.class_categories.find((element) => element.class_category.id === classData.pilates_class.class_category_id)) {
                body.push(
                    <div className='series-slider-content-details'
                         key={classData.pilates_class.name + "" + classData.pilates_class.id + "" + index}>
                        <div className='series-slider-home-image'>
                            <iframe src={classData.pilates_class.link} frameBorder="0"
                                    allow="fullscreen; muted"/>
                        </div>
                        <div className='series-slider-content-details-topic'>
                            <h3>{classData.pilates_class.name}</h3>
                            <p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                     viewBox="0 0 16 16" fill="none">
                                    <path
                                        d="M14.6666 8.00016C14.6666 11.6802 11.6799 14.6668 7.99992 14.6668C4.31992 14.6668 1.33325 11.6802 1.33325 8.00016C1.33325 4.32016 4.31992 1.3335 7.99992 1.3335C11.6799 1.3335 14.6666 4.32016 14.6666 8.00016Z"
                                        stroke="#9D9D9D" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                    <path
                                        d="M10.4734 10.1202L8.40675 8.88684C8.04675 8.6735 7.75342 8.16017 7.75342 7.74017V5.00684"
                                        stroke="#9D9D9D" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                </svg>
                                {classData.pilates_class.time} minutes
                            </p>
                        </div>
                    </div>
                );
            }
        });
        if (body.length === 0) {
            setShowAll(false);
        }
        return body;
    }

    if (userPricingPackage !== undefined) {
        if (userPricingPackage) {
            return (
                <div className='body-main spc-seris-01'>
                    <div className='section' style={{borderBottom: '1px solid #D6D6D6'}}>
                        <h2 className='section-title'>Classes</h2>
                        <p className='section-sub-title'>View all the Classes available for you.</p>
                    </div>
                    {/* <div className='series-main-wrapper series-container'>
                        {userPricingPackage.pricing_package.classes.length > 0 && showAll &&
                        <div className='slider-set-default user-profilesliders'>
                            <span className='slider-set-category'>All Series</span>
                            <div className='series-slider-wrapper'>
                                <Slider {...seriesAll}>
                                    {allClassesList(userPricingPackage.pricing_package.classes)}
                                </Slider>
                            </div>
                        </div>
                        }
                        {userPricingPackage.pricing_package.class_categories.map((categoryClass, index) => (
                            <div className='slider-set-default' key={"CategoryClass" + index}>
                                <span className='slider-set-category'>{categoryClass.class_category.name}</span>
                                <div className='series-slider-wrapper'>
                                    <Slider {...seriesAll}>
                                        {classList(categoryClass.class_category.classes)}
                                    </Slider>
                                </div>
                            </div>
                        ))}
                    </div> */}
                    <div className='series-main-wrapper series-container'>
                        {/* {userPricingPackage.pricing_package.classes.length > 0 && showAll ? (
                            <div className='slider-set-default'>
                                <span className='slider-set-category'>All Series</span>
                                <div className='series-slider-wrapper'>
                                    <Slider {...seriesAll}>
                                        {allClassesList(userPricingPackage.pricing_package.classes)}
                                    </Slider>
                                </div>
                            </div>
                        ) : (
                            showAll && <p className='no-data'>No data found.</p>
                        )} */}

                        {userPricingPackage.pricing_package.class_categories.map((categoryClass, index) => (
                            <div className='slider-set-default' key={"CategoryClass" + index}>
                                <span className='slider-set-category'>{categoryClass.class_category.name}</span><div className='series-new-image-box'>
                                <div className='image-ser-sub-item'>
                                    <img src={categoryClass.class_category.image} alt={categoryClass.class_category.name}/>
                                    <p className='image-description'>
                                        {categoryClass.class_category.description}
                                    </p>
                                </div>
                            </div>
                            <div className='series-slider-wrapper separate-slider'>
                                    {categoryClass.class_category.classes && categoryClass.class_category.classes.length > 0 ? (
                                        <Slider {...seriesAll}>
                                            {classList(categoryClass.class_category.classes)}
                                        </Slider>
                                    ) : (
                                        <p className='coming-soon'>Coming soon...</p>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )
        } else {
            return (
                <div className='body-main spc-seris-01'>
                    <div className='section' style={{borderBottom: '1px solid #D6D6D6'}}>
                        <h2 className='section-title'>You have not subscribed to any package.</h2>
                    </div>
                </div>
            );
        }
    } else {
        return (
            <Loading/>
        );
    }
}
